<template>
  <div class="work-page super-netball">

    <div class="container-block">
    	<!-- Header -->
    	<section class="header">
        <img src="/static/images/SN_1.jpg"/>
    		<div class="meta">
    			<p class="client">SUPER NETBALL</p>
    			<a class="project">ALL ON THE LINE</a>
    		</div>
    		<div class="copy container">
    			<div class="col-1">
    				<h1>It’s like war but with a netball.</h1>
    			</div>
    			<div class="col-2">
    				<p>The biggest names, the biggest clubs, the world’s most elite and intense netball competition. It’s all on the line in the Super Netball.</p>
    			</div>
    		</div>
    	</section>

      <div class="spacer-lg"></div>

      <img src="/static/images/SN_2.jpg"/>

    </div>

    <!-- <div class="spacer-lg"></div> -->

    <div style="padding:56.25% 0 0 0; position:relative;">
      <!-- <div 
      id="poster-image"
      class="poster-image poster-image-1"
      style="background: url('/static/images/SN_placeholder.png') center center; background-size: cover"
      @click="playVideo(1)"
      ></div> -->
      <iframe 
        id="video-1"
        class="video"
        src="https://player.vimeo.com/video/363715766?title=0&byline=0&portrait=0" 
        style="position:absolute;top:0;left:0;width:100%;height:100%;" 
        frameborder="0" 
        allow="fullscreen" 
        allowfullscreen>
      </iframe>
    </div>

    <!-- <div class="spacer-lg"></div> -->

    <div class="container-block">

      <div class="standout-copy" style="text-align: left; width: 100%;">
        Tension, drama, rivalry, these are things all sport fans seek.
      </div>

      <div class="spacer-lg"></div>

      <img src="/static/images/SN_3.jpg" style="width:90%"/>

      <img src="/static/images/SN_4.jpg" style="width:40%; float: right; position: relative; z-index: 3; margin-top: -10%;"/>

      <div class="clear"></div>

       <div class="two-col" style="justify-content: space-between;">
        <div class="col" style="width: 49%">
          <!-- <img src="/static/images/SN_placeholder-2.png" style="margin-top: -10%;"/> -->

          <div style="padding:56.25% 0 0 0; position:relative; margin-top: -10%;">
            <video
                id="video-2"
                style="position:absolute;top:0;left:0;width:100%;height:100%;outline:none" 
                frameborder="0"
                controls
                poster="/static/images/SN_placeholder-2.png"
                >
                  <source src="/static/videos/SN_VIDEO_01.mp4?autoplay=0" type="video/mp4">
              </video>
          </div>

        </div>
        <div class="col" style="width: 49%; display: flex; flex-direction: column; justify-content: space-between;">

          <div style="padding:56.25% 0 0 0; position:relative; margin-bottom: -180%;">
            <video
                id="video-3"
                style="position:absolute;top:0;left:0;width:100%;height:100%;outline:none" 
                frameborder="0"
                controls
                poster="/static/images/SN_placeholder-3.png"
                >
                  <source src="/static/videos/SN_VIDEO_02.mp4?autoplay=0" type="video/mp4">
              </video>
          </div>

        </div>
      </div>

       <div class="spacer-lg" style="margin-top: 17%;"></div>

       <img src="/static/images/SN_5.jpg"/>

       <div style="width: 49%; margin-left: 15%; margin-top: -6%">
         <div style="padding:56.25% 0 0 0; position:relative;">
              <video
                id="video-4"
                style="position:absolute;top:0;left:0;width:100%;height:100%;outline:none" 
                frameborder="0"
                controls
                poster="/static/images/SN_placeholder-4.png"
                >
                  <source src="/static/videos/SN_VIDEO_03.mp4?autoplay=0" type="video/mp4">
              </video>
          </div>
       </div>

      <div class="spacer-lg"></div>

      <img src="/static/images/SN_6.jpg" style="margin-top: 6%;"/>

      <div class="footer">
        <div class="prev">
          <router-link to="/nike-city-fc">
            <img src="/static/images/prev.svg"/>
          </router-link>
        </div>
        <div class="footer-contact">
          <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
          <span>&copy; {{date}} by Blood UTD</span>
        </div>
        <div class="next">
          <router-link to="/wsw">
            <img src="/static/images/next.svg"/>
          </router-link>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'super-netball',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  },
  methods: {
    playVideo(id) {
      $(".poster-image-" + id).hide();
      var symbol = $("#video-" + id)[0].src.indexOf("?") > -1 ? "&" : "?";
      //modify source to autoplay and start video
      $("#video-" + id)[0].src += symbol + "autoplay=1";
    }
  },
  mounted() {
    // $(".video").hover(function(event) {
    //     if(event.type === "mouseenter") {
    //         $(this).attr("controls", "");
    //     } else if(event.type === "mouseleave") {
    //         $(this).removeAttr("controls");
    //     }
    // });
    // $('.video').click(function(el) {
    //     this.paused ? this.play() : this.pause();
    // });

    // document.getElementById("poster-image").onclick = function() { 
    //     $(".poster-image").hide();
    //     $(".video")[0].src += "&autoplay=1";
    // };
    // document.getElementById("poster-image-2").onclick = function() { 
    //     $(".poster-image-2").hide();
    //     // $("#video-2")[0].src += "&autoplay=1";
    //     var symbol = $("#video-2")[0].src.indexOf("?") > -1 ? "&" : "?";
    //     //modify source to autoplay and start video
    //     $("#video-2")[0].src += symbol + "autoplay=1";
    // };
    // document.getElementById("poster-image-3").onclick = function() { 
    //     $(".poster-image-3").hide();
    //     $("#video-3")[0].src += "&autoplay=1";
    // };
    // document.getElementById("poster-image-4").onclick = function() { 
    //     $(".poster-image-4").hide();
    //     $("#video-4")[0].src += "&autoplay=1";
    // };

  }
}
</script>

<style lang="scss" scoped>

.super-netball {

  .poster {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      z-index: 3;
      cursor: pointer;
  }

}

</style>
